var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productsStatistics" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        { staticStyle: { "margin-right": "10px" } },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "seach" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择供货日期",
                },
                model: {
                  value: _vm.from.supply_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "supply_time", $$v)
                  },
                  expression: "from.supply_time",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择一级品类",
                  },
                  model: {
                    value: _vm.from.first_cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "first_cid", $$v)
                    },
                    expression: "from.first_cid",
                  },
                },
                _vm._l(_vm.searchdata.first_category_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择二级品类",
                  },
                  model: {
                    value: _vm.from.second_cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "second_cid", $$v)
                    },
                    expression: "from.second_cid",
                  },
                },
                _vm._l(_vm.searchdata.second_category_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择三级品类",
                  },
                  model: {
                    value: _vm.from.third_cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "third_cid", $$v)
                    },
                    expression: "from.third_cid",
                  },
                },
                _vm._l(_vm.searchdata.category_tag_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择集配中心",
                  },
                  model: {
                    value: _vm.from.logistics_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "logistics_business_id", $$v)
                    },
                    expression: "from.logistics_business_id",
                  },
                },
                _vm._l(_vm.searchdata.business_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择创建日期",
                },
                model: {
                  value: _vm.from.create_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "create_at", $$v)
                  },
                  expression: "from.create_at",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入商品名称", clearable: "" },
                model: {
                  value: _vm.from.product_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "product_title", $$v)
                  },
                  expression: "from.product_title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入货号", clearable: "" },
                model: {
                  value: _vm.from.sku_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "sku_code", $$v)
                  },
                  expression: "from.sku_code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入供应商名称", clearable: "" },
                model: {
                  value: _vm.from.supplier_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "supplier_title", $$v)
                  },
                  expression: "from.supplier_title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入业务名称", clearable: "" },
                model: {
                  value: _vm.from.buyer_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "buyer_name", $$v)
                  },
                  expression: "from.buyer_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", icon: "el-icon-upload2" },
              on: { click: _vm.exportHandle },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "title", align: "center", label: "供货日" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(_vm.from1.supply_time) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "title", align: "center", label: "商品名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.title) +
                          "(" +
                          _vm._s(scope.row.sku_code) +
                          ") "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "first_cid_name",
                align: "center",
                label: "一级品类",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "cid_name", align: "center", label: "二级品类" },
            }),
            _c("el-table-column", {
              attrs: { prop: "tag_name", align: "center", label: "三级品类" },
            }),
            _c("el-table-column", {
              attrs: { prop: "price", align: "center", label: "开市价" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "supplier_name",
                align: "center",
                label: "供货商",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "buyer_name", align: "center", label: "业务员" },
            }),
            _c("el-table-column", {
              attrs: { prop: "create_at", align: "center", label: "创建时间" },
            }),
            _c("el-table-column", {
              attrs: { prop: "stock", align: "center", label: "库存数量" },
            }),
            _c("el-table-column", {
              attrs: { prop: "promotion_tag", align: "center", label: "标签" },
            }),
            _c("el-table-column", {
              attrs: { prop: "weight", align: "center", label: "净重 " },
            }),
            _c("el-table-column", {
              attrs: { prop: "weight_price", align: "center", label: "净单价" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "score_30_day",
                align: "center",
                label: "总得分30天得分",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "qty", align: "center", label: "销售总件数" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "all_weight",
                align: "center",
                label: "销售总毛重（斤）",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "all_price",
                align: "center",
                label: "销售总金额",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "member_count",
                align: "center",
                label: "累次购买人次",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "tdc_count",
                align: "center",
                label: "辐射城市仓数量",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }